// Styles
import logoURL from './logo/hercules.png';

import { Elm } from './src/Main.elm'

var app = Elm.Main.init({
  node: document.getElementById('main'),
  flags: {
    apiURL: window.location.origin,
    htmlURL: window.location.origin,
    logoURL: logoURL,
    currentTime: Date.now()
  }
});

app.ports.paddleSubscribe.subscribe(function(data) {
  Paddle.Setup({ vendor: 49326 });
  Paddle.Checkout.open({
    product: data.product,
    passthrough: data.passthrough,
    successCallback: app.ports.paddleSubscriptionSucceeded.send,
    override: data.paymentLink
  });
});


