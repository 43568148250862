module Main exposing (main)

import Api.Data exposing (Account, ClientInfo)
import Api.Request.Account as AccountAPI
import Api.Request.Client as ClientAPI
import Auth
import Browser
import Browser.Navigation as Nav
import Hercules.Account
import Hercules.AppState exposing (AppState(..))
import Hercules.Breadcrumbs
import Hercules.Flags exposing (Flags)
import Hercules.Html exposing (clickable)
import Hercules.Http
import Hercules.Route exposing (Route, toHref)
import Hercules.Routing
import Hercules.Session exposing (User(..), sendRequest, sendRequest1)
import Html exposing (..)
import Html.Attributes exposing (..)
import Http
import RemoteData
import SemanticUI.Modules.Dropdown as Dropdown
import Task
import Time exposing (Posix)
import Url
import Widget.HttpErrorMessage



-- MAIN


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = UrlChanged -- someone clicked a link
        , onUrlRequest = UrlRequest -- url shall be shown
        }



-- MODEL


type alias Model =
    { appstate : AppState
    , accounts : RemoteData.WebData (List Account)

    -- counts number of times we've fetched accounts - needed when installing projects
    , accountsRefreshCount : Int
    , clientInfo : RemoteData.WebData ClientInfo
    , user : RemoteData.WebData Account
    , key : Nav.Key
    , route : Route
    , url : Url.Url
    , flags : Flags
    , breadcrumbs : Hercules.Breadcrumbs.Model
    , timeZone : Maybe Time.Zone
    , accountDropdown : Dropdown.State
    }


init : Flags -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flags url key =
    let
        route =
            Hercules.Route.fromUrl url

        ( bmodel, bmsg ) =
            Hercules.Breadcrumbs.init
    in
    ( { flags = flags
      , key = key
      , route = route
      , url = url
      , appstate = NotReady
      , accounts = RemoteData.Loading
      , clientInfo = RemoteData.Loading
      , accountsRefreshCount = 0
      , user = RemoteData.Loading
      , breadcrumbs = bmodel
      , timeZone = Nothing
      , accountDropdown = Dropdown.Closed
      }
    , Cmd.batch
        [ sendRequest1 flags.apiURL
            (AccountAPI.apiV1AccountsGet
                Nothing
                Nothing
            )
            GetAccounts
        , sendRequest1 flags.apiURL ClientAPI.apiV1ClientInfoGet GetClientInfo
        , Cmd.map BreadcrumbsMsg bmsg
        , Task.perform SetTimeZone Time.here
        ]
    )



-- UPDATE


type Msg
    = UrlRequest Browser.UrlRequest
    | UrlChanged Url.Url
    | GetClientInfo (Result Http.Error ClientInfo)
    | GetAccounts (Result Http.Error (List Account))
    | RoutingMsg Hercules.Routing.Msg
    | BreadcrumbsMsg Hercules.Breadcrumbs.Msg
    | UpdateTime Posix
    | UpdateAccounts (RemoteData.WebData (List Account))
    | SetTimeZone Time.Zone
    | SignOut
    | SignOutResponse (RemoteData.WebData ())
    | ToggleAccountDropdown Dropdown.State


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        -- Navigation msgs
        UrlRequest urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    -- if it's a known frontend url, handle it internally, otherwise load the page
                    case Hercules.Route.fromUrl url of
                        Hercules.Route.NotFound _ ->
                            ( model, Nav.load (Url.toString url) )

                        _ ->
                            ( { model
                                | accountDropdown =
                                    -- Close the account dropdown if opened.
                                    case model.accountDropdown of
                                        Dropdown.Opened ->
                                            Dropdown.Closing

                                        x ->
                                            x
                              }
                            , Nav.pushUrl model.key (Url.toString url)
                            )

                -- https://github.com/elm/browser/issues/34
                Browser.External "" ->
                    ( model, Cmd.none )

                Browser.External url ->
                    ( model, Nav.load url )

        UrlChanged url ->
            let
                route =
                    Hercules.Route.fromUrl url
            in
            routingUpdate { model | route = route, url = url } (Hercules.Routing.RouteChanged route)

        SignOutResponse _ ->
            ( model, Nav.load "/" )

        RoutingMsg routingMsg ->
            routingUpdate model routingMsg

        -- Session msgs
        GetAccounts result ->
            sessionUpdate
                { model
                    | accounts =
                        let
                            response =
                                RemoteData.fromResult result

                            newAccounts =
                                -- We need to be able to recover from a bad session
                                -- (500s, outdated tokens on the backend and such) by signin in.
                                if RemoteData.isFailure response && isSignIn model.route then
                                    RemoteData.Success []

                                else
                                    response
                        in
                        newAccounts
                }

        GetClientInfo result ->
            sessionUpdate
                { model
                    | user =
                        RemoteData.fromResult result
                            |> RemoteData.map (\x -> List.head x.personalAccounts)
                            |> RemoteData.andThen
                                (\x ->
                                    case x of
                                        Just v ->
                                            RemoteData.Success v

                                        Nothing ->
                                            RemoteData.Failure (Http.BadBody "Not logged in")
                                )
                    , clientInfo = RemoteData.fromResult result
                }

        BreadcrumbsMsg bMsg ->
            let
                ( nextmodel, nextmsg ) =
                    Hercules.Breadcrumbs.update model.appstate bMsg model.breadcrumbs
            in
            ( { model | breadcrumbs = nextmodel }
            , Cmd.map BreadcrumbsMsg nextmsg
            )

        UpdateAccounts result ->
            case model.appstate of
                Ready session rmodel ->
                    case result of
                        RemoteData.Success accounts ->
                            let
                                newsession =
                                    Hercules.Session.update session (Hercules.Session.UpdateAccounts accounts)
                            in
                            ( { model | appstate = Ready newsession rmodel }, Cmd.none )

                        -- TODO: rather use refreshing combinator
                        _ ->
                            ( model, Cmd.none )

                _ ->
                    ( model, Cmd.none )

        UpdateTime time ->
            case model.appstate of
                Ready session rmodel ->
                    let
                        newsession =
                            Hercules.Session.update session (Hercules.Session.UpdateTime time)
                    in
                    ( { model
                        | appstate = Ready newsession rmodel
                        , accountsRefreshCount = model.accountsRefreshCount + 1
                      }
                      -- refresh account list as someone might install one
                    , if model.accountsRefreshCount < 4 then
                        sendRequest session
                            (AccountAPI.apiV1AccountsGet Nothing Nothing)
                            (RemoteData.fromResult >> UpdateAccounts)

                      else
                        Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        SetTimeZone zone ->
            ( { model | timeZone = Just zone }, Cmd.none )

        SignOut ->
            ( model, Auth.apiV1ApiAuthSignOutPost { basePath = model.flags.apiURL, onSend = RemoteData.fromResult >> SignOutResponse } )

        ToggleAccountDropdown x ->
            ( { model | accountDropdown = x }, Cmd.none )


isSignIn : Route -> Bool
isSignIn route =
    case route of
        Hercules.Route.SignIn _ ->
            True

        _ ->
            False


routingUpdate : Model -> Hercules.Routing.Msg -> ( Model, Cmd Msg )
routingUpdate model routingMsg =
    case model.appstate of
        Ready session rmodel ->
            let
                newsession =
                    Hercules.Session.update session (Hercules.Session.UpdateRoute model.route)

                ( nextrmodel, nextrmsg ) =
                    Hercules.Routing.update newsession routingMsg rmodel
            in
            ( { model
                | appstate = Ready newsession nextrmodel
              }
            , Cmd.map RoutingMsg nextrmsg
            )

        -- Nothing to do here, routing only happens once session is ready
        _ ->
            ( model, Cmd.none )


sessionUpdate : Model -> ( Model, Cmd Msg )
sessionUpdate model =
    case model.timeZone of
        Nothing ->
            ( model, Cmd.none )

        Just zone ->
            case model.clientInfo of
                RemoteData.Success clientInfo ->
                    case model.accounts of
                        RemoteData.Success accounts ->
                            case clientInfo.personalAccounts of
                                [] ->
                                    sessionInit model zone Guest clientInfo []

                                user :: _ ->
                                    sessionInit model zone (LoggedIn user) clientInfo accounts

                        RemoteData.Failure error ->
                            if Hercules.Http.is401 error then
                                sessionInit model zone Guest clientInfo []

                            else
                                ( { model | appstate = Failed error }, Cmd.none )

                        _ ->
                            ( model, Cmd.none )

                RemoteData.Failure error ->
                    ( { model | appstate = Failed error }, Cmd.none )

                _ ->
                    ( model, Cmd.none )


sessionInit : Model -> Time.Zone -> Hercules.Session.User -> ClientInfo -> List Account -> ( Model, Cmd Msg )
sessionInit model zone user clientInfo accounts =
    let
        session =
            { user = user
            , clientInfo = clientInfo
            , flags = model.flags
            , accounts = accounts
            , key = model.key
            , route = model.route
            , currentTime = Time.millisToPosix model.flags.currentTime
            , timeZone = zone
            }

        ( rmodel, rmsg ) =
            Hercules.Routing.init session model.route
    in
    ( { model | appstate = Ready session rmodel }
    , Cmd.map RoutingMsg rmsg
    )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    let
        sessionSubs =
            case model.appstate of
                Ready session rmodel ->
                    Sub.map RoutingMsg (Hercules.Routing.subscriptions session rmodel)

                _ ->
                    Sub.none
    in
    Sub.batch
        [ Time.every 3000 UpdateTime
        , sessionSubs
        ]



-- VIEW


view : Model -> Browser.Document Msg
view model =
    let
        page =
            case model.appstate of
                NotReady ->
                    { title = "Loading"
                    , body =
                        [ div [ class "ui loader active massive text" ]
                            [ text "Loading Hercules CI" ]
                        ]
                    }

                Ready session rmodel ->
                    Hercules.Routing.view session rmodel

                Failed error ->
                    { title = "Error"
                    , body = [ Widget.HttpErrorMessage.view True error ]
                    }

        breadcrumbs =
            Html.map BreadcrumbsMsg (Hercules.Breadcrumbs.view model.appstate model.route model.breadcrumbs)
    in
    { title = page.title ++ " - Hercules CI"
    , body =
        [ div [ id "main" ]
            [ nav [ class "ui fixed inverted menu" ] [ viewMenu model ]
            , div [ id "wrap" ]
                [ div [ id "ci-content", class "ui main container" ]
                    (div [ style "margin" "20px 0" ] [ breadcrumbs ]
                        :: List.map (Html.map RoutingMsg) page.body
                    )
                , viewFooter
                ]
            ]
        ]
    }


viewFooter : Html Msg
viewFooter =
    let
        items =
            [ a [ class "item", href "https://www.iubenda.com/privacy-policy/14101005" ]
                [ text "Privacy Policy" ]
            , a
                [ Hercules.Route.toHref Hercules.Route.Terms
                , class "item"
                ]
                [ text "Terms" ]
            , a [ class "item", href "https://status.hercules-ci.com/" ]
                [ text "Service Status" ]
            , a [ class "item", href "mailto:help@hercules-ci.com" ]
                [ text "Support" ]
            ]
    in
    footer
        [ class "ui inverted vertical footer segment" ]
        [ div [ class "ui center aligned container" ]
            (List.intersperse (text " | ") items)
        ]


viewMenu : Model -> Html Msg
viewMenu model =
    let
        dashboardUrl =
            Hercules.Route.toString Hercules.Route.Dashboard

        rootUrl =
            case model.appstate of
                Ready session _ ->
                    case session.user of
                        Guest ->
                            "/"

                        _ ->
                            dashboardUrl

                _ ->
                    dashboardUrl

        logo =
            [ img
                [ src model.flags.logoURL
                , style "margin-right" "10px"
                , style "height" "2.5em"
                , style "object-fit" "contain"
                ]
                []
            , text " Hercules CI"
            ]
                ++ (case model.appstate of
                        Ready session _ ->
                            if session.clientInfo.licensedTo == "Hercules CI" then
                                []

                            else
                                [ div [ style "position" "absolute", style "bottom" "0.5ex", style "left" "4.8em", style "font-weight" "normal", style "opacity" "0.75", style "font-size" "90%" ] [ text ("for " ++ session.clientInfo.licensedTo) ] ]

                        _ ->
                            []
                   )
    in
    div [ class "ui container" ]
        [ a [ href rootUrl, class "header item" ]
            logo
        , a [ class "item", href "https://docs.hercules-ci.com" ] [ text "Docs" ]
        , a [ class "item", href "https://blog.hercules-ci.com" ] [ text "Blog" ]
        , div
            [ id "user-menu"
            , class "menu right"
            ]
            (viewMenuItems model)
        ]


redirectUrl : Url.Url -> String
redirectUrl url =
    let
        query =
            case url.query of
                Nothing ->
                    ""

                Just q ->
                    "?" ++ q

        fragment =
            case url.fragment of
                Nothing ->
                    ""

                Just f ->
                    "#" ++ f
    in
    url.path ++ Url.percentEncode (query ++ fragment)


viewMenuItems : Model -> List (Html Msg)
viewMenuItems model =
    case model.appstate of
        NotReady ->
            [ div
                [ class "ui item" ]
                [ i [ class "ui icon user large" ] []
                , span [ attribute "style" "margin-left: 10px;" ]
                    [ div [ class "ui inverted placeholder", style "width" "8em" ]
                        [ div [ class " line" ] []
                        ]
                    ]
                ]
            ]

        Failed _ ->
            -- An error has occurred. Perhaps user can try signin in (again?)
            -- _: Error message details are shown in the body.
            [ div
                [ class "ui item" ]
                [ span [ style "margin-right" "10px" ] [ text "Sign-in status unknown" ]
                , viewSignInButton model
                ]
            ]

        Ready session _ ->
            case session.user of
                LoggedIn account ->
                    let
                        cfg =
                            Dropdown.init { identifier = "account", onToggle = ToggleAccountDropdown }

                        st =
                            model.accountDropdown
                    in
                    [ Dropdown.root
                        cfg
                        st
                        div
                        [ class "item" ]
                        [ Dropdown.toggle cfg
                            st
                            div
                            [ class "" ]
                            [ Dropdown.toggle cfg st i [ class "dropdown icon" ] []
                            , Hercules.Account.viewAvatar account 35
                            , span [ style "margin-left" "10px" ] [ text account.displayName ]
                            ]
                        , Dropdown.drawer cfg
                            st
                            div
                            []
                            [ Dropdown.item a [ Hercules.Route.toHref Hercules.Route.PersonalSettings ] [ i [ class "wrench icon" ] [], text "Settings" ]
                            , Dropdown.item a (clickable SignOut) [ i [ class "icon" ] [], text "Sign out" ]
                            ]
                        ]
                    ]

                Guest ->
                    [ div
                        [ class "ui item" ]
                        (if isSignInPage model then
                            []

                         else
                            [ viewSignInButton model ]
                        )
                    ]


isSignInPage : Model -> Bool
isSignInPage model =
    case model.appstate of
        Ready _ { page } ->
            case page of
                Hercules.Routing.SignInPage _ ->
                    True

                _ ->
                    False

        _ ->
            False


viewSignInButton : Model -> Html Msg
viewSignInButton model =
    a
        [ class "ui inverted button"
        , toHref (Hercules.Route.SignIn { redirect = Just (redirectUrl model.url) })
        ]
        [ i
            [ class "icon user" ]
            []
        , text " Sign in"
        ]
